<template>
  <div class="confirm_project">
    <NavBar />
    <Breadcrumb :breadcrumb="state.listBreadcrumb" />
    <ProjectTitle />
    <div class="container">
      <div class="main-area">
        <div class="confirm_project__form">
          <div class="confirm_project__form_box">
            <h3 class="confirm_project__form_title">登録内容確認</h3>
            <form class="horizontal">
              <div class="form-ctrl">
                <label  class="text">プロジェクト名</label>
                <div class="row" v-if="!state.preload">
                  <div class="col">
                    <div class="form-group">
                      <p>{{ state.data.title || "" }}</p>
                      <template
                        v-if="
                          state.errorValidateMessage &&
                          state.errorValidateMessage.title
                        "
                      >
                        <ErrorMessage
                          v-for="message in state.errorValidateMessage.title"
                          :message="message"
                          :key="message"
                        >
                        </ErrorMessage>
                      </template>
                    </div>
                  </div>
                </div>
                <SimplePreloader v-else />
              </div>
              <div class="form-ctrl">
                <label  class="text">相談パターン</label>
                <div class="row" v-if="!state.preload">
                  <div class="col">
                    <div class="form-group">
                      <p>{{ state.categoryName }}</p>
                      <template
                        v-if="
                          state.errorValidateMessage &&
                          state.errorValidateMessage.category_id
                        "
                      >
                        <ErrorMessage
                          v-for="message in state.errorValidateMessage
                            .category_id"
                          :message="message"
                          :key="message"
                        >
                        </ErrorMessage>
                      </template>
                    </div>
                  </div>
                </div>
                <SimplePreloader v-else />
              </div>
              <div class="form-ctrl">
                <label  class="text height-27">相談概要</label>
                <div class="row" v-if="!state.preload">
                  <div class="col">
                    <div class="form-group">
                      <pre>{{ state.data.description }}</pre>
                      <template
                        v-if="
                          state.errorValidateMessage &&
                          state.errorValidateMessage.description
                        "
                      >
                        <ErrorMessage
                          v-for="message in state.errorValidateMessage
                            .description"
                          :message="message"
                          :key="message"
                        >
                        </ErrorMessage>
                      </template>
                    </div>
                  </div>
                </div>
                <SimplePreloader v-else />
              </div>
              <div class="form-ctrl">
                <label  class="text height-27">データタイプ</label>
                <div class="row" v-if="!state.preload">
                  <div class="col">
                    <div class="form-group">
                      <template v-if="state.data.data_types.length">
                        <p v-for="item in state.data.data_types" :key="item.id">{{ item.name }}</p>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-ctrl">
                <label  class="text height-27">対応可能な時間帯</label>
                <div class="row" v-if="!state.preload">
                  <div class="col">
                    <div class="form-group">
                      <template v-if="state.data.available_datetimes">
                        <p v-for="item in state.data.available_datetimes" :key="item.id">{{ item.name }}</p>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-ctrl">
                <label  class="text height-27">企業からのアピールポイント</label>
                <div class="row" v-if="!state.preload">
                  <div class="col">
                    <div class="form-group">
                      <pre>{{ state.data.appeal_point_from_company }}</pre>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-ctrl">
                <label  class="text height-27">受験生に求めること</label>
                <div class="row" v-if="!state.preload">
                  <div class="col">
                    <div class="form-group">
                      <pre>{{ state.data.expectation_for_student }}</pre>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-ctrl">
                <label  class="text height-27">地域ハブ団体からのアピールポイント</label>
                <div class="row" v-if="!state.preload">
                  <div class="col">
                    <div class="form-group">
                      <pre>{{ state.data.regional_hub_comment }}</pre>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-ctrl">
                <label  class="text height-27">地域ハブ団体連絡先</label>
                <div class="row" v-if="!state.preload">
                  <div class="col">
                    <div class="form-group">
                      <pre>{{ state.data.regional_hub_contact }}</pre>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-ctrl">
                <label  class="text height-27">よくあるQ&A</label>
                <div class="row" v-if="!state.preload">
                  <div class="col">
                    <div class="form-group">
                      <pre>{{ state.data.qa }}</pre>
                      <template
                          v-if="
                          state.errorValidateMessage &&
                          state.errorValidateMessage.qa
                        "
                      >
                        <ErrorMessage
                            v-for="message in state.errorValidateMessage
                            .qa"
                            :message="message"
                            :key="message"
                        >
                        </ErrorMessage>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-ctrl">
                <label  class="text height-27">その他・連絡事項</label>
                <div class="row" v-if="!state.preload">
                  <div class="col">
                    <div class="form-group">
                      <pre>{{ state.data.note }}</pre>
                      <template
                          v-if="
                          state.errorValidateMessage &&
                          state.errorValidateMessage.note
                        "
                      >
                        <ErrorMessage
                            v-for="message in state.errorValidateMessage
                            .note"
                            :message="message"
                            :key="message"
                        >
                        </ErrorMessage>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn_group">
                <button
                  class="btn-md btn-light-gray shadow"
                  type="button"
                  @click="goBack"
                >
                  キャンセル
                </button>
                <button
                  :disabled="state.loading"
                  :class="{ submitting: state.loading }"
                  type="button"
                  class="btn-md btn-blue shadow"
                  @click="submit"
                >
                  <i
                    v-if="state.loading"
                    class="fas fa-circle-notch fa-spin"
                  ></i
                  >登録申請
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="@/presentation/views/organization/dashboard/confirm-project/ConfirmProject.ts">
</script>

<style lang="scss" src="@/presentation/views/organization/dashboard/confirm-project/ConfirmProject.scss" scoped>
</style>
