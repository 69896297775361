import { reactive, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import NavBar from "@/presentation/components/navigation/Navigation.vue";
import Textarea from "@/presentation/components/textarea/Textarea.vue";
import LanguageUtil from "@/commons/LanguageUtil";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import DIContainer from "@/core/DIContainer";
import MasterInteractor from "@/domain/usecases/MasterInteractor";
import ProjectInteractor from "@/domain/usecases/ProjectInteractor";
import OrganizationProjectInteractor from "@/domain/usecases/OrganizationProjectInteractor";
import Project from "@/domain/entity/Project";
import routePath from "@/commons/RoutePath";
import { useStore } from "vuex";
import SimplePreloader from "@/presentation/components/preloader/simple-preloader/SimplePreloader.vue";
import SkillTagPreloader from "@/presentation/components/preloader/skill-tag-preloader/SkillTagPreloader.vue";
import ProjectTitle from "@/presentation/components/project-title/ProjectTitle.vue";
export default defineComponent({
    name: "CreateProject",
    components: {
        Breadcrumb: Breadcrumb,
        NavBar: NavBar,
        Textarea: Textarea,
        ErrorMessage: ErrorMessage,
        SimplePreloader: SimplePreloader,
        SkillTagPreloader: SkillTagPreloader,
        ProjectTitle: ProjectTitle
    },
    setup: function () {
        var router = useRouter();
        var store = useStore();
        var masterInteractor = DIContainer.instance.get(MasterInteractor);
        var projectInteractor = DIContainer.instance.get(ProjectInteractor);
        var organizationProjectInteractor = DIContainer.instance.get(OrganizationProjectInteractor);
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            listBreadcrumb: [
                {
                    route: "/organization/dashboard",
                    name: "ダッシュボード"
                },
                {
                    route: "/organization/dashboard/projects",
                    name: "プロジェクト管理"
                },
                {
                    route: "",
                    name: "登録内容確認"
                }
            ],
            data: new Project,
            categoryName: "",
            application_period: "",
            working_period: "",
            loading: false,
            errorValidateMessage: [],
            serverError: null,
            preload: true,
            prefectureName: "",
            cityName: "",
        });
        function formatter(number) {
            if (number == null || number == undefined || number == "") {
                return "￥0";
            }
            return new Intl.NumberFormat('ja-JP', {
                style: 'currency',
                currency: 'JPY',
            }).format(number);
        }
        function formatDate(date) {
            if (date == null || date == undefined || date == "") {
                return "-";
            }
            var dateFormat = new Date(date), d = "" + dateFormat.getDate(), m = "" + (dateFormat.getMonth() + 1), y = dateFormat.getFullYear();
            if (m.length < 2) {
                m = "0" + m;
            }
            if (d.length < 2) {
                d = "0" + d;
            }
            return [y, m, d].join("/");
        }
        function initData() {
            var _a;
            state.data = store.state.organizationProjectData;
            if (!state.data.title) {
                return router.back();
            }
            var dataTitle = JSON.parse(JSON.stringify(state.data));
            dataTitle.image_file_name = dataTitle.file_base64 ? dataTitle.file_base64 : ((_a = dataTitle.image_file_name) !== null && _a !== void 0 ? _a : "");
            store.commit("setProjectInfo", dataTitle);
            if (state.data.working_period_start_at && state.data.working_period_end_at) {
                state.working_period = formatDate(state.data.working_period_start_at) + " ~ " + formatDate(state.data.working_period_end_at);
            }
            Promise.all([
                projectInteractor.getProjectCategories().then(function (result) {
                    state.preload = false;
                    if (!result.data) {
                        return;
                    }
                    for (var _i = 0, _a = Object.keys(result.data); _i < _a.length; _i++) {
                        var key = _a[_i];
                        var value = result.data[key];
                        if (value.id == state.data.category_id) {
                            state.categoryName = value.name;
                            break;
                        }
                    }
                }),
            ]).catch(function (error) { state.preload = false; });
        }
        function submit() {
            state.loading = true;
            if (state.data.project_id) {
                state.data.progress_status = "UNAPPROVED";
                return organizationProjectInteractor.updateOrganizationProject(state.data.project_id, state.data).then(function (result) {
                    state.loading = false;
                    store.commit("setOrganizationProjectData", {});
                    store.commit("setProjectUnApproved", result.data.id);
                    router.push(routePath.PROJECT_DETAIL + result.data.id);
                }).catch(function (error) {
                    state.loading = false;
                    state.errorValidateMessage = {};
                    if (error && error.validation_errors) {
                        Object.assign(state.errorValidateMessage, error.validation_errors);
                    }
                    if (error && error.message) {
                        state.serverError = error.message;
                    }
                });
            }
            else {
                delete state.data.project_id;
                state.data.progress_status = "UNAPPROVED";
                return organizationProjectInteractor.creatProject(state.data).then(function (result) {
                    state.loading = false;
                    store.commit("setOrganizationProjectData", {});
                    store.commit("setProjectUnApproved", result.data.id);
                    router.push(routePath.PROJECT_DETAIL + result.data.id);
                }).catch(function (error) {
                    state.loading = false;
                    state.errorValidateMessage = {};
                    if (error && error.validation_errors) {
                        Object.assign(state.errorValidateMessage, error.validation_errors);
                    }
                    if (error && error.message) {
                        state.serverError = error.message;
                    }
                });
            }
        }
        function goBack() {
            router.back();
        }
        onMounted(function () {
            initData();
        });
        return {
            state: state,
            goBack: goBack,
            submit: submit
        };
    }
});
